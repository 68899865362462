import { ref } from 'vue';
import { getMessaging, getToken } from 'firebase/messaging';
import firebaseApp from './firebase';
import { firebaseVapidKey } from './config';
import { useInitStore } from '@/stores/init';

const fcmToken = async (): Promise<string> => {
  const notificationPermissionStatus = ref('');

  const messaging = getMessaging(firebaseApp);

  const permission = await Notification.requestPermission();
  notificationPermissionStatus.value = permission;

  if (permission === 'granted') {
    try {
      const token = await getToken(messaging, {
        vapidKey: firebaseVapidKey,
      });

      if (token) {
        useInitStore().updateWebPushToken(token);
        return token;
      } else {
        return '';
      }
    } catch (error) {
      return '';
    }
  } else {
    return '';
  }
};

export default fcmToken;
