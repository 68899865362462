import { Ref, ref } from 'vue';
import { defineStore } from 'pinia';

export const useAlertStore = defineStore('alert', () => {
  const alertVisible: Ref<boolean> = ref(false);
  const alertMessage: Ref<string> = ref('');
  const alertLabel: Ref<string[]> = ref([]);
  const alertType: Ref<number> = ref(0);

  const alertShow = (message: string, label: string[], type?: number): void => {
    alertVisible.value = true;
    alertMessage.value = message;
    alertLabel.value = label;
    alertType.value = type || 0;
  };

  const alertHide = (): void => {
    alertVisible.value = false;
  };

  return {
    alertVisible,
    alertMessage,
    alertLabel,
    alertType,
    alertShow,
    alertHide,
  };
});
