import { useRoute } from 'vue-router';
import router from '@/router';
import { Post, Put, Patch, Delete } from '@/api/method';
import * as Types from '@/types';
import { useInitStore } from '@/stores/init';
import i18n from '@/utils/i18n';

const t = i18n.global.t;

/* 로그인, 액세스 토큰 갱신 데이터 store 저장 */
const apiSetInit = (result: Types.CreateUserResponse): void => {
  useInitStore().setInit({
    accessToken: `Bearer ${result.apiToken}`,
    nickname: result.nickname,
  });
};
/* 닉네임 갱신 데이터 store 저장 */
const apiUpdateNickname = (value: string): void => {
  useInitStore().updateNickname(value);
};
/* 로그아웃, 탈퇴 액세스 토큰 리셋 */
const apiResetAccessToken = (): void => {
  useInitStore().resetAccessToken();
  useInitStore().resetWebPushToken();
};
/* push state */
const getPushState = (): any => {
  if (useInitStore().os === 'NoApp') {
    return 'NONE';
  } else {
    if (useInitStore().appPushState) {
      return 'ALLOWED';
    } else {
      return 'DISALLOWED';
    }
  }
};
/* push 토큰 */
const getFcmToken = async (): Promise<string> => {
  if (useInitStore().os === 'NoApp') {
    return useInitStore().webPushToken;
  } else {
    return useInitStore().appPushToken;
  }
};
/* 로그인 테스트 API */
export const createUserTest = async (): Promise<void> => {
  const url = '/api/v1/user/test';
  const response = await Post<Types.CreateUserResponse>(url);
  const result: Types.CreateUserResponse = response.data.response;
  apiSetInit(result);

  if (sessionStorage.getItem('push')) {
    router.replace({
      name: 'Main',
      params: { date: sessionStorage.getItem('push') },
    });
    sessionStorage.clear();
  } else {
    router.replace({ name: 'Root' });
  }
};
/* 액세스 토큰 갱신 API */
export const createUserToken = async (): Promise<boolean> => {
  const url = '/api/v1/user/token';
  const response = await Post<Types.CreateUserTokenResponse>(url);
  const result: Types.CreateUserTokenResponse = response.data.response;
  apiSetInit(result);

  return response.data.success;
};
/* 푸시 정보 업데이트 API */
export const PatchUserPush = async (): Promise<boolean> => {
  const url = '/api/v1/user/push';
  const req = {
    pushState: getPushState(),
    pushToken: await getFcmToken(),
  };
  const response = await Patch<Types.PatchUserPushResponse>(url, req);

  return response.data.success;
};
/* 로그인 API */
export const createUser = async (type: string): Promise<void> => {
  const route = useRoute();
  const url = '/api/v1/user';
  const req = {
    redirectUri: `${window.location.origin}/${type}`,
    code: `${route.query.code}`,
    loginType: `${type.toUpperCase()}`,
  };
  const response = await Post<Types.CreateUserResponse>(url, req);
  const result: Types.CreateUserResponse = response.data.response;
  apiSetInit(result);

  if (sessionStorage.getItem('push')) {
    router.replace({
      name: 'Main',
      params: { date: sessionStorage.getItem('push') },
    });
    sessionStorage.clear();
  } else {
    router.replace({ name: 'Root' });
  }
};
/* 사용자정보 수정 API */
export const patchUser = async (req: {
  nickname: string;
}): Promise<void | boolean> => {
  if (!confirm(t('user.changeNickname'))) {
    return false;
  }
  const url = '/api/v1/user';
  await Patch<Types.PatchUserResponse>(url, req);

  apiUpdateNickname(req.nickname);
};
/* 로그아웃 API */
export const updateUser = async (): Promise<void | boolean> => {
  if (!confirm(t('user.logout'))) {
    return false;
  }
  const url = '/api/v1/user';
  await Put<Types.UpdateUserResponse>(url);

  apiResetAccessToken();

  router.replace({ name: 'Login' });
};
/* 탈퇴 API */
export const deleteUser = async (): Promise<void | boolean> => {
  if (!confirm(t('user.withdraw'))) {
    return false;
  }
  const url = '/api/v1/user';
  await Delete<Types.DeleteUserResponse>(url);

  apiResetAccessToken();

  router.replace({ name: 'Login' });
};
