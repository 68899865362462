import { useInitStore } from './init';
import { useLoadingStore } from './loading';
import { useAlertStore } from './alert';

const useStores = () => {
  const initStore = useInitStore();
  const loadingStore = useLoadingStore();
  const alertStore = useAlertStore();

  return {
    initStore,
    loadingStore,
    alertStore,
  };
};

export default useStores;
