export const firebaseConfig = {
  apiKey: 'AIzaSyCoWkNwqSzdmpn7hZ-cUuvnXwEZ0dONjME',
  authDomain: 'candoeng-front.firebaseapp.com',
  projectId: 'candoeng-front',
  storageBucket: 'candoeng-front.appspot.com',
  messagingSenderId: '1055869733226',
  appId: '1:1055869733226:web:af00db2978ed6696dcfd13',
  measurementId: 'G-FJQDHMTMGZ',
};

export const firebaseVapidKey =
  'BGMJNX0G-4z77gvrRY-PsiL87RHrNSUl2lgcvHTZ91FyHs1ilfNgaTy8PFSatqj_mz0f8We1UblGgGLerN5cZkw';
