import { useInitStore } from '@/stores/init';
import * as Types from '@/types';

class ThemeSwitcher implements Types.ThemeSwitcher {
  private theme: Types.Theme;

  constructor() {
    this.theme = this.getLocalTheme() || this.getSystemTheme();
  }

  private getLocalTheme(): Types.Theme | null {
    return localStorage.getItem('theme') as Types.Theme | null;
  }

  private getSystemTheme(): Types.Theme {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';
  }

  public setTheme(selectedTheme: string): void {
    if (useInitStore().os === 'NoApp') {
      localStorage.setItem('theme', selectedTheme);
    }
    document.documentElement.setAttribute('data-theme', selectedTheme);
  }

  public setInitialTheme(): void {
    if (this.getLocalTheme()) {
      localStorage.setItem('theme', this.theme!);
    }
    document.documentElement.setAttribute('data-theme', this.theme!);
  }

  public themeSwitch(): void {
    this.theme = this.theme === 'dark' ? 'light' : 'dark';
    this.applyTheme();
  }

  private applyTheme(): void {
    this.setTheme(this.theme!);
  }
}

export default new ThemeSwitcher();
