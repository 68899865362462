import dayjs from 'dayjs';
import * as Types from '@/types';
class DateFormat implements Types.DateFormat {
  get routeDate(): string {
    return String(window.location.pathname.match(/\d+/));
  }

  get today(): string {
    return dayjs(new Date()).format('YYYYMMDD');
  }

  get isToday(): boolean {
    return this.routeDate === this.today;
  }

  public routeParamsDate(value: number, division: string): string {
    switch (value) {
      case 6:
        return dayjs(this.routeDate).format(`YYYY${division}MM`);
      case 8:
        return dayjs(this.routeDate).format(`YYYY${division}MM${division}DD`);
      default:
        return '';
    }
  }

  public valueDate(date: string, value: number, division: string): string {
    switch (value) {
      case 6:
        return dayjs(date).format(`YYYY${division}MM`);
      case 8:
        return dayjs(date).format(`YYYY${division}MM${division}DD`);
      default:
        return '';
    }
  }
}

export default new DateFormat();
