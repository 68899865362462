<script setup lang="ts"></script>
<template>
  <div id="loading-wrap">
    <div class="loading"></div>
  </div>
</template>
.
<style lang="scss">
#loading-wrap {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.loading {
  color: #495e57;
  position: relative;
  font-size: 11px;
  background: #495e57;
  animation: escaleY 1s infinite ease-in-out;
  width: 1em;
  height: 6em;
  animation-delay: -0.16s;
}
.loading:before,
.loading:after {
  content: '';
  position: absolute;
  top: 0;
  left: 2em;
  background: #495e57;
  width: 1em;
  height: 6em;
  animation: escaleY 1s infinite ease-in-out;
}
.loading:before {
  left: -2em;
  animation-delay: -0.32s;
}

@keyframes escaleY {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
</style>
