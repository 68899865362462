import { EventSourcePolyfill } from 'event-source-polyfill';
import { createUserToken } from '@/api';
import useStores from '@/stores';
import * as Types from '@/types';

class SSEManager implements Types.SSEManager {
  private eventSource: Types.eventSource = null;

  public initializeEventSource(): void {
    const { initStore } = useStores();

    this.eventSource = new EventSourcePolyfill(
      import.meta.env.VITE_APP_SSE_URL,
      {
        headers: {
          Authorization: initStore.accessToken,
          'Cache-Control': 'no-cache',
          Connection: 'keep-alive',
          Accept: 'text/event-stream',
        },
        withCredentials: true,
        heartbeatTimeout: 1000 * 60 * 35,
      },
    );

    this.eventSource.onerror = (): void => {
      console.log('eventSource 에러남');
      this.unsubscribe();
      setTimeout(async () => {
        if (initStore.accessToken !== '') {
          await createUserToken();
        }
      }, 1000);
    };
  }

  public addEventListener(
    eventType: string,
    listener: (event: MessageEvent) => void,
  ): void {
    if (this.eventSource) {
      this.eventSource.addEventListener(eventType, listener);
    }
  }

  public unsubscribe(): void {
    if (this.eventSource) {
      this.eventSource.close();
      console.log('SSE 구독 종료');
    }
  }

  public isValidJson(data: string): boolean {
    try {
      JSON.parse(data);
      return true;
    } catch (error) {
      return false;
    }
  }
}

export default new SSEManager();
