import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { createUserToken } from '@/api';
import {
  // resetAxiosInProgress,
  windowInProgress,
} from '@/utils/windowInProgress';
import useStores from '@/stores';
import DateFormat from '@/utils/DateFormat';

type RouteGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => void;

export const RouteGuard: RouteGuard = async (to, _, next) => {
  // resetAxiosInProgress();
  windowInProgress();
  const { initStore, alertStore } = useStores();

  /** 로그인왔는데 push가 있거나 토큰이 있을떄 */
  if (to.name === 'Login') {
    if (initStore.accessToken !== '') {
      return next({ name: 'Root' });
    }

    if (to.query?.push) {
      sessionStorage.setItem('push', to.query.push as string);
    } else {
      sessionStorage.clear();
    }

    return next();
  }

  /** 파이어베이스 사용불가능하면 */
  if (to.meta.notiCheck && initStore.useFirebase === false) {
    return next({ name: 'Induce' });
  }

  /** 날짜 검증 */
  const paramsCheckRoute = ['Main', 'Diary'];
  if (
    paramsCheckRoute.includes(to.name as string) &&
    (Number(to.params.date) > Number(DateFormat.today) ||
      Number(to.params.date) < 19000101 ||
      isNaN(to.params.date as any))
  ) {
    return next({ name: 'NotFound' });
  }

  /** 로그인 체크 */
  if (to.meta.tokenCheck && initStore.accessToken === '') {
    await createUserToken();
    return next();
  }

  next();

  alertStore.alertHide();
};
