// css
import './assets/css/css.scss';
import 'vue-scroll-picker/lib/style.css';

import { createPinia } from 'pinia';
import { createApp } from 'vue';

import App from './App.vue';
import router from './router';

// plugins
import dayjs from '@/plugins/dayjs';
import Vue3TouchEvents from 'vue3-touch-events';
// utils
import ThemeSwitcher from '@/utils/ThemeSwitcher';
import i18n from '@/utils/i18n';
// font-awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// calendar
import { setupCalendar } from 'v-calendar';
import { useInitStore } from './stores/init';

library.add(fas);
ThemeSwitcher.setInitialTheme();

const pinia = createPinia();
const app = createApp(App);

/** axios, bridge, sse */
window.axiosInProgress = [];
window.appInProgress = [];
window.sseInProgress = [];
/** 앱 테마 */
window.getAppTheme = (res: string): void => {
  const appRes = JSON.parse(res);
  ThemeSwitcher.setTheme(appRes.data.theme);
};
/** 앱에서 호출 (시스템 알림 or 서비스 알림 설정 변경 시) */
window.getAlarmState = (res: string): void => {
  const appRes = JSON.parse(res);
  const { updateAppPushState, updateAppPermissionState } = useInitStore();
  // alert('서비스 알림: ' + appRes.data.flag+ '시스템 알림: ' + appRes.data.permission);
  updateAppPushState(appRes.data.flag);
  updateAppPermissionState(appRes.data.permission);
};
/** 앱에서 푸시 */
window.moveWebView = (res: string): void => {
  const appRes = JSON.parse(res);
  router.push({ name: appRes.data.name, params: { date: appRes.data.date } });
};
/** 안드로이드 뒤로가기 */
window.androidBack = () => {
  router.back();
};

if (import.meta.env.MODE === 'prod') {
  console.log = function () {};
} else {
  app.config.globalProperties.$devtools = true;
}

app.use(pinia);
app.use(router);
app.use(dayjs);
app.use(Vue3TouchEvents);
app.use(i18n);
app.use(setupCalendar, {});
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');

// VITE_APP_URL = https://1candoeng.today
