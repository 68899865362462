import { App } from 'vue';
import dayjs from 'dayjs';

export default {
  install(app: App): void {
    // 컴포넌트 인스턴스에서 전체적으로 접근하기 위해
    app.config.globalProperties.$dayjs = dayjs;
    // setup 함수에서도 접근할 수 있게 주입
    app.provide('dayjs', dayjs);
  },
};
