import { Ref } from 'vue';
import axios, {
  InternalAxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from 'axios';
import { statusHandler } from './status';
import useStores from '@/stores';
import {
  cancelToken,
  removeAxiosInProgress,
  updateLoadingStore,
} from '@/utils/windowInProgress';
import i18n from '@/utils/i18n';

const axiosInstance = axios.create({
  baseURL: '/',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 1000 * 60 * 5,
});

const onRequest = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  window.axiosInProgress.push(config.url as string);
  updateLoadingStore();
  const { method, url } = config;
  const locale = (i18n.global.locale as unknown as Ref<string>).value;
  config.headers['Accept-Language'] = locale;
  config.headers.Authorization = useStores().initStore.accessToken;
  console.log(`🛫 [API - REQUEST] ${method?.toUpperCase()} ${url}`);
  console.log(config);
  if (config.url !== '/api/v1/user/push') {
    cancelToken.cancel(config.url);
  }
  return config;
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  removeAxiosInProgress(response.config.url as string);
  const { method, url } = response.config;
  console.log(`🛬 [API - RESPONSE] ${method?.toUpperCase()} ${url}`);
  console.log(response);

  if (response.headers.authorization) {
    useStores().initStore.updateAccessToken(response.headers.authorization);
  }

  updateLoadingStore();
  return response;
};

const onError = (error: AxiosError | Error): Promise<AxiosError> => {
  window.axiosInProgress = [];
  updateLoadingStore();
  if (axios.isAxiosError(error)) {
    const { method, url } = error.config as InternalAxiosRequestConfig;
    console.log(`🚨 [API - ERROR] ${method?.toUpperCase()} ${url}`);
    console.log(error.config);
    statusHandler(
      error.response?.status as number,
      method as string,
      url as string,
    );
  }
  return Promise.reject(error);
};

axiosInstance.interceptors.request.use(onRequest);
axiosInstance.interceptors.response.use(onResponse, onError);

export default axiosInstance;
