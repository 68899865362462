<script setup lang="ts">
import { useAlertStore } from '@/stores/alert';

const emit = defineEmits<{
  alertClose: [];
  alertCancel: [];
  alertCheck: [];
}>();

const alertStore = useAlertStore();
const { alertHide } = alertStore;

const alertClose = (): void => {
  alertHide();
  emit('alertClose');
};

const alertCancel = (): void => {
  alertHide();
  emit('alertCancel');
};

const alertCheck = (): void => {
  alertHide();
  emit('alertCheck');
};
</script>

<template>
  <div class="alert-wrap">
    <div class="alert-body">
      <div class="alert-content">{{ alertStore.alertMessage }}</div>
      <div class="alert-button" v-if="alertStore.alertLabel.length === 1">
        <button @click="alertClose">
          {{ alertStore.alertLabel[0] }}
        </button>
      </div>
      <div class="alert-button" v-else>
        <button @click="alertCancel">
          {{ alertStore.alertLabel[0] }}
        </button>
        <button @click="alertCheck">
          {{ alertStore.alertLabel[1] }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
