import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { RouteGuard } from './RouteGuard';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Root',
    meta: { tokenCheck: false, notiCheck: false },
    component: () => import('@/views/RootView.vue'),
  },
  {
    path: '/main/:date',
    name: 'Main',
    meta: { tokenCheck: true, notiCheck: false },
    component: () => import('@/views/MainView.vue'),
  },
  {
    path: '/diary/:date',
    name: 'Diary',
    component: () => import('@/views/DiaryView.vue'),
    meta: { tokenCheck: true, notiCheck: false },
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('@/views/SettingView.vue'),
    meta: { tokenCheck: true, notiCheck: false },
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('@/views/UserView.vue'),
    meta: { tokenCheck: true, notiCheck: false },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginView.vue'),
    meta: { tokenCheck: false, notiCheck: true },
  },
  {
    path: '/kakao',
    name: 'Kakao',
    component: () => import('@/views/KakaoView.vue'),
    meta: { tokenCheck: false, notiCheck: false },
  },
  {
    path: '/apple',
    name: 'Apple',
    component: () => import('@/views/AppleView.vue'),
    meta: { tokenCheck: false, notiCheck: false },
  },
  {
    path: '/induce',
    name: 'Induce',
    component: () => import('@/views/induceView.vue'),
    meta: { tokenCheck: false, notiCheck: false },
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('@/views/ErrorView.vue'),
    meta: { tokenCheck: false, notiCheck: true },
  },
  {
    path: '/not-found',
    name: 'NotFound',
    component: () => import('@/views/NotFoundView.vue'),
    meta: { tokenCheck: false, notiCheck: true },
  },
  {
    /** 일치하는 경로가 없을때 */
    path: '/:pathMatch(.*)*',
    redirect: '/not-found',
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  scrollBehavior: () => ({ top: 0 }),
  routes,
});

router.beforeEach((to, from, next) => {
  // routeGuard 함수 실행
  RouteGuard(to, from, next);
});

router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location.href = to.fullPath;
    }
  }
});

export default router;
