import { useInitStore } from '@/stores/init';
import fcmToken from './fcmToken';

const fcmAvailable = async () => {
  const initStore = useInitStore();

  try {
    if (
      typeof window !== 'undefined' &&
      'serviceWorker' in navigator &&
      'PushManager' in window
    ) {
      fcmToken();
    } else {
      initStore.updateFirebase(false);
    }
  } catch (error) {
    initStore.updateFirebase(false);
  }
};

export default fcmAvailable;
