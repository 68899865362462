import { Ref, ref, computed, watch } from 'vue';
import { defineStore } from 'pinia';
import { PatchUserPush } from '@/api';
// "설정에서 알림을 허용하시면 교정 알림을 받을 수 있습니다. 이동하시겠습니까?"
export const useInitStore = defineStore('init', () => {
  /** --- 액세스토큰 --- */
  const accessToken: Ref<string> = ref('');
  /** 액세스토큰 업데이트 */
  const updateAccessToken = (payload: string): void => {
    accessToken.value = `Bearer ${payload}`;
  };
  /** 액세스토큰 리셋 */
  const resetAccessToken = (): void => {
    accessToken.value = '';
  };

  /** --- 닉네임 --- */
  const nickname: Ref<string> = ref('');
  /** 닉네임 업데이트 */
  const updateNickname = (payload: string): void => {
    nickname.value = payload;
  };

  /** --- 액세스토큰, 닉네임 초기세팅 --- */
  const setInit = (payload: object): void => {
    Object.entries(payload).forEach(([key, value]) => {
      if (
        key in
        {
          accessToken,
          nickname,
        }
      ) {
        eval(key).value = value;
      }
    });
  };

  /** --- 파이어베이스 푸시알림 사용가능 여부 --- */
  const useFirebase: Ref<boolean | null> = ref(null);
  /** 파이어베이스 푸시알림 사용가능 여부 업데이트 */
  const updateFirebase = (payload: boolean): void => {
    useFirebase.value = payload;
  };

  /** --- 웹 푸시 토큰 --- */
  const webPushToken: Ref<string> = ref('');
  /** 웹 푸시 토큰 업데이트 */
  const updateWebPushToken = (payload: string): void => {
    webPushToken.value = payload;
  };
  /** 웹 푸시 토큰 리셋 */
  const resetWebPushToken = (): void => {
    webPushToken.value = '';
  };

  /** --- 앱 푸시 토큰 --- */
  const appPushToken: Ref<string> = ref('');
  /** 앱 푸시 토큰 업데이트 */
  const updateAppPushToken = (payload: string): void => {
    appPushToken.value = payload;
  };
  /** 앱 푸시 사용 여부 */
  const appPushState: Ref<boolean | null> = ref(null);
  /** 앱 푸시 사용 여부 업데이트 */
  const updateAppPushState = (payload: boolean): void => {
    appPushState.value = payload;
  };
  /** 시스템 푸시 사용 여부 */
  const appPermissionState: Ref<boolean | null> = ref(null);
  /** 시스템 푸시 사용 여부 업데이트 */
  const updateAppPermissionState = (payload: boolean): void => {
    appPermissionState.value = payload;
  };

  /** --- 푸시 업데이트 API 호출 가능 여부 --- */
  /** 웹 */
  const fcmAvailable = computed((): boolean => {
    return accessToken.value !== '' && webPushToken.value !== '';
  });
  /** 앱 */
  const appPushAvailable = computed((): boolean => {
    return (
      accessToken.value !== '' &&
      appPushToken.value !== '' &&
      appPushState.value !== null
    );
  });
  /** 푸시 업데이트 가능할때 API 호출 */
  watch(
    [() => fcmAvailable.value, () => appPushAvailable.value],
    ([fcmValue, appPushValue]) => {
      if (fcmValue || appPushValue) {
        PatchUserPush();
      }
    },
  );
  /** 액세스토큰 or 웹 푸시 토큰 업데이트 or 앱 푸시 사용 여부 업데이트 됐을때 푸시 업데이트 API 호출 */
  watch(
    [accessToken, webPushToken, appPushState],
    (
      [newAccessToken, newWebPushToken, newAppPushState],
      [oldAccessToken, oldWebPushToken, oldAppPushState],
    ) => {
      if (
        (newAccessToken !== '' &&
          oldAccessToken !== '' &&
          newAccessToken !== oldAccessToken) ||
        (newWebPushToken !== '' &&
          oldWebPushToken !== '' &&
          newWebPushToken !== oldWebPushToken) ||
        (oldAppPushState !== null &&
          newAppPushState !== null &&
          oldAppPushState !== newAppPushState)
      ) {
        PatchUserPush();
      }
    },
  );

  /** --- 웹 or ios or android --- */
  const os = computed((): string => {
    const userAgentString = navigator.userAgent;
    return /IOSonecandoeng/.test(userAgentString)
      ? 'IOS'
      : /AndroidOnecandoeng/.test(userAgentString)
        ? 'Android'
        : 'NoApp';
  });

  /* 웹앱 */
  const webApp = computed((): boolean => {
    return (window as any).navigator.standalone;
  });

  return {
    accessToken,
    updateAccessToken,
    resetAccessToken,
    nickname,
    updateNickname,
    setInit,
    useFirebase,
    updateFirebase,
    webPushToken,
    updateWebPushToken,
    resetWebPushToken,
    appPushToken,
    updateAppPushToken,
    appPushState,
    updateAppPushState,
    appPermissionState,
    updateAppPermissionState,
    os,
    webApp,
    fcmAvailable,
  };
});
