import { Ref, ref } from 'vue';
import { defineStore } from 'pinia';

export const useLoadingStore = defineStore('loading', () => {
  const loading: Ref<boolean> = ref(false);

  const loadingStart = (): void => {
    loading.value = true;
  };

  const loadingStop = (): void => {
    loading.value = false;
  };

  return { loading, loadingStart, loadingStop };
});
