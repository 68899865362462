import axios from 'axios';
import useStores from '@/stores';

/** ---- axios ---- */
export const cancelToken = axios.CancelToken.source();

export const removeAxiosInProgress = (url: string | undefined): void => {
  if (!url) return;
  const index = window.axiosInProgress.indexOf(url);
  if (index !== -1) {
    window.axiosInProgress.splice(index, 1);
  }
};

export const resetAxiosInProgress = (): void => {
  window.axiosInProgress.forEach((url: string) => {
    cancelToken.cancel(url);
    removeAxiosInProgress(url);
  });
};

/** ---- appBridge ---- */
export const pushAppInProgress = (callbackName: string): void => {
  if (callbackName === '') {
    return;
  } else {
    window.appInProgress.push(callbackName as string);
    updateLoadingStore();
  }
};

export const removeAppInProgress = (callbackName: string | undefined): void => {
  if (!callbackName) return;
  const index = window.appInProgress.indexOf(callbackName);
  if (index !== -1) {
    window.appInProgress.splice(index, 1);
    updateLoadingStore();
  }
};

/** ---- sseEvent ---- */
export const pushSseInProgress = (value: string): void => {
  window.sseInProgress.push(value);
  updateLoadingStore();
};

export const removeSseInProgress = (value: string): void => {
  if (!value) return;
  const index = window.sseInProgress.indexOf(value);
  if (index !== -1) {
    window.sseInProgress.splice(index, 1);
    updateLoadingStore();
  }
};

/** ---- reset ---- */
export const windowInProgress = (): void => {
  window.axiosInProgress = [];
  window.appInProgress = [];
  window.sseInProgress = [];
  updateLoadingStore();
};

/** ---- loadingUpdate ---- */
export const updateLoadingStore = (): void => {
  const { loadingStore } = useStores();
  if (
    window.axiosInProgress.length === 0 &&
    window.appInProgress.length === 0 &&
    window.sseInProgress.length === 0
  ) {
    loadingStore.loadingStop();
  } else {
    loadingStore.loadingStart();
  }
};
