import { AxiosResponse } from 'axios';
import { Post, Get, Put, Delete } from '@/api/method';
import * as Types from '@/types';
import { diary409 } from './status';
import i18n from '@/utils/i18n';

const t = i18n.global.t;

/* 일기 단건 조회 API */
export const readDiary = async (req: {
  wrttenDate: string;
}): Promise<Types.ReadDiaryResponse> => {
  const url = `/api/v1/diary/${req.wrttenDate}`;
  const response = await Get<Types.ReadDiaryResponse>(url);
  const result: Types.ReadDiaryResponse = response.data.response;

  return result;
};
/* 일기 월별 조회 API */
export const readDiaryMonthly = async (req: {
  wrttenMonth: string;
}): Promise<Types.ReadDiaryMonthlyResponse> => {
  const url = `/api/v1/diary/${req.wrttenMonth}/monthly-list`;
  const response = await Get<Types.ReadDiaryMonthlyResponse>(url);
  const result: Types.ReadDiaryMonthlyResponse = response.data.response;

  return result;
};
/* 일기 등록 API */
export const createDiaryNew = async (req: {
  dateStr: string;
  content: string;
}): Promise<
  AxiosResponse<Types.CommonResponse<Types.CreateDiaryNewResponse>> | boolean
> => {
  try {
    if (!confirm(t('diary.register'))) {
      return false;
    }
    const url = '/api/v1/diary/new';
    const response = await Post<Types.CreateDiaryNewResponse>(url, req);

    return response;
  } catch (error: any) {
    if (error.response.status === 409) {
      diary409(0, req.dateStr);
    }
    return false;
  }
};
/* 일기 수정 API */
export const updateDiary = async (req: {
  writtenDate: string;
  content: string;
}): Promise<
  AxiosResponse<Types.CommonResponse<Types.CreateDiaryNewResponse>> | boolean
> => {
  try {
    if (!confirm(t('diary.modify'))) {
      return false;
    }
    const url = `/api/v1/diary/${req.writtenDate}`;
    const response = await Put<Types.CreateDiaryNewResponse>(url, req.content);

    return response;
  } catch (error: any) {
    if (error.response.status === 409) {
      diary409(0, req.writtenDate);
    }
    return false;
  }
};
/* 일기 삭제 API */
export const deleteDiary = async (req: {
  writtenDate: string;
}): Promise<
  AxiosResponse<Types.CommonResponse<Types.DeleteDiaryResponse>> | boolean
> => {
  if (!confirm(t('diary.delete'))) {
    return false;
  }
  const url = `/api/v1/diary/${req.writtenDate}`;
  const response = await Delete<Types.DeleteDiaryResponse>(url);

  return response;
};
/* 일기 리뷰 요청 API */
export const createDiaryReview = async (req: {
  type: number;
  writtenDate: string;
}) => {
  try {
    if (req.type === 1 && !confirm(t('diary.requestCorrection'))) {
      return false;
    }

    const url = `/api/v1/diary/${req.writtenDate}/review`;
    const response = await Post<Types.CreateDiaryReviewResponse>(url);

    return response;
  } catch (error: any) {
    if (error.response.status === 409) {
      diary409(1);
    }
    return false;
  }
};
