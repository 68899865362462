import useStores from '@/stores';
import { getFcmTokenResponse } from '@/types/app';
import callNative from '@/bridge/AppBridge';
import i18n from '@/utils/i18n';

const t = i18n.global.t;

/* ---- 서비스 알림 토큰 ---- */
export const getFcmToken = async (): Promise<void> => {
  try {
    const res: getFcmTokenResponse = await callNative('getFcmToken', {}, true);
    useStores().initStore.updateAppPushToken(res.fcmToken);
  } catch (error) {
    alert('error' + error);
  }
};

/**
 * ---- 서비스 알림 설정 상태 ----
 * 시스템 알림 설정 off -> 서비스 알림 설정 off
 * 시스템 알림 설정 on -> 서비스 알림 설정 on/off
 */
export const getAlarmState = async (): Promise<void> => {
  try {
    await callNative('getAlarmState', {}, false);
  } catch (error) {
    alert('error' + error);
  }
};

/**
 * ---- 서비스 알림 설정 변경 ----
 * 시스템 알림 설정 off -> 권한 요청
 * 시스템 알림 설정 on -> 서비스 알림 설정 on/off
 */
export const setAlarmState = async (): Promise<void | false> => {
  try {
    await callNative(
      'setAlarmState',
      { flag: !useStores().initStore.appPushState },
      true,
    );
    await getAlarmState();
    return;
  } catch (error) {
    return runAlarmSetting();
  }
};

/* ---- 시스템 알림 설정 오픈 ---- */
export const runAlarmSetting = (): void => {
  if (!confirm(t('permissions.notification.appRequest'))) {
    return;
  }
  callNative('runAlarmSetting', {}, false);
};
