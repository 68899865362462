import useStores from '@/stores';
import { AppResponse } from '@/types/app';
import {
  pushAppInProgress,
  removeAppInProgress,
} from '@/utils/windowInProgress';

/** command, params 동적 */
const callNative = (
  command: string,
  params: Record<string, unknown>,
  callback: boolean,
): any => {
  const appTime = Date.now();
  const messageBody = {
    id: appTime,
    command,
    params,
    callback: callback ? command : '',
  };
  const strMessageBody = JSON.stringify(messageBody);

  try {
    switch (useStores().initStore.os) {
      case 'IOS':
        window.webkit.messageHandlers.onecandoeng.postMessage(messageBody);
        pushAppInProgress(messageBody.callback);
        console.log('Bridge 요청 : ', messageBody);
        return nativeCallback(messageBody.callback);
      case 'Android':
        window.onecandoeng[command](strMessageBody);
        pushAppInProgress(messageBody.callback);
        console.log('Bridge 요청 : ', strMessageBody);
        return nativeCallback(messageBody.callback);
      default:
        return;
    }
  } catch (error) {
    alert('NATIVE APIs ERR.' + error);
    return;
  }
};

const nativeCallback = (callbackName: string): Promise<any> | void => {
  if (callbackName === '') {
    return;
  }
  return new Promise((resolve, reject) => {
    (window as any)[callbackName] = (res: string) => {
      const appRes: AppResponse = JSON.parse(res);
      if (appRes.isSuccess) {
        console.log('Bridge 응답 : ', appRes);
        resolve(appRes.data);
      } else {
        console.error(`Error in ${callbackName}:`, appRes);
        reject(appRes);
      }
      removeAppInProgress(callbackName);
    };
  });
};

export default callNative;

// /** 컴포넌트에서 addEventListener
//  * sayHello
//  */
// // --------- vue --------- //
// window.sayHello = new CustomEvent("NativeEvent");
// const nativeEventCallback = (event) => {
//   alert(`event receive from Native ` + event);
// };
// window.addEventListener("NativeEvent", nativeEventCallback);
// window.removeEventListener("NativeEvent", nativeEventCallback);

// // --------- ios 데이터 전달 X --------- //
// webView.evaluateJavascript("window.dispatchEvent(sayHello)") { (result, error) in }

// // --------- ios 데이터 전달 O --------- //
// let dataToSend = "This is the data I want to send"
// let javascriptCode = "window.sayHello = new CustomEvent('NativeEvent', { detail: { message: '\(dataToSend)' } }); window.dispatchEvent(sayHello);"
// webView.evaluateJavaScript(javascriptCode) { (result, error) in
//     if let error = error {
//         print("Error evaluating JavaScript: \(error.localizedDescription)")
//     } else {
//         print("JavaScript executed successfully")
//     }
// }

// // --------- android 데이터 전달 X --------- //
// webView.evaulateJavascript("window.dispatchEvent(sayHello)")

// // --------- android 데이터 전달 O --------- //
// String dataToSend = "This is the data I want to send";
// String javascriptCode = "javascript:window.sayHello = new CustomEvent('NativeEvent', { detail: { message: '" + dataToSend + "' } }); window.dispatchEvent(sayHello);";
// webView.evaluateJavascript(javascriptCode, new ValueCallback<String>() {
//     @Override
//     public void onReceiveValue(String value) {
//         // JavaScript 코드 실행 후의 결과를 처리할 수 있음
//     }
// });

// /** window.~~
//  * helloWorld
//  */
// // --------- vue --------- //
// window.NativeInterface = {
//   helloWorld: () => {
//     // your javascript code
//   },
// }

// // --------- ios 데이터 전달 X --------- //
// webView.evaluateJavascript("javascript:window.NativeInterface.helloWorld()") { (result, error) in }

// // --------- ios 데이터 전달 O --------- //
// let dataToSend = "Hello from iOS"
// let javascriptCode = "window.NativeInterface.helloWorld('\(dataToSend)')"
// webView.evaluateJavaScript(javascriptCode) { (result, error) in
//     if let error = error {
//         print("Error evaluating JavaScript: \(error.localizedDescription)")
//     } else {
//         print("JavaScript executed successfully")
//     }
// }

// // --------- android 데이터 전달 X --------- //
// webView.evaluateJavascript("window.NativeInterface.helloWorld()")
// webView.loadUrl("javascript:window.NativeInterface.helloWorld()")

// // --------- android 데이터 전달 O --------- //
// String dataToSend = "Hello from Android";
// String javascriptCode = "javascript:window.NativeInterface.helloWorld('" + dataToSend + "')";
// webView.evaluateJavascript(javascriptCode, new ValueCallback<String>() {
//     @Override
//     public void onReceiveValue(String value) {
//         // JavaScript 코드 실행 후의 결과를 처리할 수 있음
//     }
// });
