import { createI18n } from 'vue-i18n';
import enUS from '@/locales/en.json';
import koKR from '@/locales/ko.json';
import * as Types from '@/types';

const supportedLanguages = ['ko', 'en'] as const;
type MessageSchema = typeof enUS;
type SupportedLanguage = (typeof supportedLanguages)[number];
const getLocale = (): SupportedLanguage => {
  const lang = localStorage.getItem('lang') as Types.Lang | null;
  if (lang && supportedLanguages.includes(lang)) {
    return lang;
  }
  return 'ko';
};

const i18n = createI18n<[MessageSchema], Types.Lang>({
  // options
  locale: getLocale(),
  fallbackLocale: 'en',
  legacy: false,
  messages: {
    en: enUS,
    ko: koKR,
  },
});

export default i18n;
