<script setup lang="ts">
import { onBeforeMount, provide, ref, Ref, watch } from 'vue';
import TheView from '@/layouts/TheView.vue';
import router from './router';
import TheLoadingView from '@/layouts/TheLoadingView.vue';
import * as Types from '@/types';
import useStores from '@/stores';
import fcmAvailable from '@/firebase/fcmAvailable';
import { getFcmToken, getAlarmState } from '@/utils/AppPush';
import SSEManager from '@/utils/SSEManager';
import CommonAlert from './components/common/commonAlert.vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

onBeforeMount(() => {
  init();
});

const { t } = useI18n();
const { initStore, loadingStore, alertStore } = useStores();
const route = useRoute();

const init = (): Promise<void> | void => {
  if (initStore.os === 'NoApp') {
    return fcmAvailable();
  } else {
    getFcmToken();
    getAlarmState();
    return;
  }
};

/* ---- commonAlert ---- */
const sseRESAlert = (): void => {
  if (initStore.os !== 'NoApp' || initStore.accessToken === '') {
    return;
  }
  if (route.path === `/main/${sseDateStr.value}`) {
    alertStore.alertShow(t('sse.response'), [t('button.check')]);
  } else {
    alertStore.alertShow(t('sse.action'), [
      t('button.next'),
      t('button.check'),
    ]);
  }
};
const alertCheck = (): void => {
  router.push({ name: 'Main', params: { date: sseDateStr.value } });
};

/* ---- sseDiary ---- */
const sseDiary: Ref<Types.DiaryEntry> = ref({
  content: '',
  dateStr: '',
  postKey: 0,
  reviewContent: '',
  state: '',
});
const sseType: Ref<Types.sseType> = ref('');
const sseDateStr: Ref<Types.sseDateStr> = ref('');
/* ---- SSEManager ---- */
const SSEMessage = (): void => {
  /** 일기 구독 DUM */
  SSEManager.addEventListener('DUM', event => {
    console.log('일기 구독 DUM', event);
  });
  /** 일기 신규등록 NEW */
  SSEManager.addEventListener('NEW', event => {
    console.log('일기 신규등록 구독 NEW', event);
    // if (sseString !== event.data && SSEManager.isValidJson(event.data)) {
    if (SSEManager.isValidJson(event.data)) {
      // sseString = event.data;
      console.log('일기 신규등록 이벤트 NEW', event.data);
      sseType.value = 'NEW';
      sseDiary.value = JSON.parse(event.data);
    }
  });
  /** 일기 수정 UPD */
  SSEManager.addEventListener('UPD', event => {
    console.log('일기 수정 구독 UPD', event);
    if (SSEManager.isValidJson(event.data)) {
      console.log('일기 수정 이벤트 UPD', event.data);
      sseType.value = 'UPD';
      sseDiary.value = JSON.parse(event.data);
    }
  });
  /** 일기 교정 요청 REQ */
  SSEManager.addEventListener('REQ', event => {
    console.log('일기 교정 요청 구독 REQ', event);
    if (SSEManager.isValidJson(event.data)) {
      console.log('일기 교정 요청 이벤트 REQ', event.data);
      sseType.value = 'REQ';
      sseDiary.value = JSON.parse(event.data);
    }
  });
  /** 일기 교정 완료 RES */
  SSEManager.addEventListener('RES', event => {
    console.log('일기 교정 완료 구독 RES', event);
    if (SSEManager.isValidJson(event.data)) {
      console.log('일기 교정 완료 이벤트 RES', event.data);
      sseType.value = 'RES';
      sseDiary.value = JSON.parse(event.data);
      sseDateStr.value = JSON.parse(event.data).dateStr;
      sseRESAlert();
    }
  });
  /** 일기 삭제 DEL */
  SSEManager.addEventListener('DEL', event => {
    console.log('일기 삭제 구독 DEL', event);
    if (SSEManager.isValidJson(event.data)) {
      console.log('일기 삭제 이벤트 DEL', event.data);
      sseType.value = 'DEL';
      sseDiary.value = JSON.parse(event.data);
    }
  });
  /** 닉네임 변경 NIC */
  SSEManager.addEventListener('NIC', event => {
    console.log('닉네임 변경 구독 NIC', event);
    if (SSEManager.isValidJson(event.data)) {
      console.log('닉네임 변경 이벤트 NIC', event.data);
      const newNick = JSON.parse(event.data).newNick;
      initStore.updateNickname(newNick);
    }
  });
};
provide('sseType', sseType);
provide('sseDiary', sseDiary);
watch(
  () => initStore.accessToken,
  newValue => {
    if (newValue !== '') {
      SSEManager.initializeEventSource();
      SSEMessage();
    }
  },
);
/**
Today was a day of simple pleasures and quiet reflection.
As I woke up to the gentle rays of the morning sun filtering through my window,
I felt a sense of calm wash over me.
The chirping of birds outside served as a gentle soundtrack to the beginning of my day.
 */
</script>

<template>
  <TheLoadingView v-if="loadingStore.loading" />
  <Teleport to="#commonAlert" v-if="alertStore.alertVisible">
    <CommonAlert @alertCheck="alertCheck" />
  </Teleport>
  <TheView />
</template>

<style lang="scss" scoped></style>
