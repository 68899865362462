import router from '@/router';
import useStores from '@/stores';
import i18n from '@/utils/i18n';

const t = i18n.global.t;

export const statusHandler = (
  value: number,
  method: string,
  url: string,
): void => {
  const { initStore } = useStores();
  if (method === 'post' && url.startsWith('/api/v1/user')) {
    router.replace({ name: 'Login' });
    return;
  }
  switch (value) {
    case 401:
    case 412:
      initStore.resetAccessToken();
      initStore.resetWebPushToken();
      router.replace({ name: 'Login' });
      break;
    default:
      router.replace({ name: 'Error' });
      break;
  }
};

export const diary409 = (type: number, dateStr?: string): void => {
  switch (type) {
    case 0:
      alert(t('diary.sameDateEntry'));
      router.replace({ name: 'Main', params: { date: dateStr } });
      break;
    case 1:
      alert(t('diary.sameRequestPending'));
      break;
  }
};
